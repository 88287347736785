var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"ma-0 pa-0 pt-5 mt-5",attrs:{"justify":"center"}},[_c('div',{staticClass:"pb-6 opening"},[_c('v-icon',{staticClass:"mb-4",attrs:{"color":"purple","size":"45px"}},[_vm._v("$vuetify.icons.two")]),_c('h2',{staticClass:"font-weight-bold pl-3 mb-4"},[_vm._v("Ansprechpartner angeben")]),_c('p',[_vm._v(" Bitte geben Sie uns Ihre Kontaktdaten an, damit wir uns bei eventuellen Rückfragen zu den Verwertungsquoten bei Ihnen melden können. Eine Bestätigung Ihrer Angaben wird an die von Ihnen als Kontakt eingetragene E-Mail versendet. ")])],1),_c('v-form',{ref:"contactForm",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('div',{staticClass:"form-container"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',{staticClass:"pl-3 from-label-bold"},[_vm._v("Vorname")]),_c('v-text-field',{ref:"firstName",attrs:{"placeholder":"z.B. Maximilian","clearable":"","outlined":"","dense":"","flat":"","required":"","rules":_vm.nameRules},model:{value:(_vm.reporter.firstName),callback:function ($$v) {_vm.$set(_vm.reporter, "firstName", $$v)},expression:"reporter.firstName"}},[_c('template',{slot:"append-outer"},[(
                  _vm.attemptSubmit && _vm.$refs.firstName && _vm.$refs.firstName.hasError
                )?_c('v-icon',{attrs:{"size":"32px"}},[_vm._v("$vuetify.icons.defaultError")]):_c('v-icon')],1)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',{staticClass:"pl-3 from-label-bold"},[_vm._v("Nachname")]),_c('v-text-field',{ref:"lastName",attrs:{"placeholder":"z.B. Mustermann","clearable":"","outlined":"","dense":"","flat":"","required":"","rules":_vm.nameRules},model:{value:(_vm.reporter.lastName),callback:function ($$v) {_vm.$set(_vm.reporter, "lastName", $$v)},expression:"reporter.lastName"}},[_c('template',{slot:"append-outer"},[(
                  _vm.attemptSubmit && _vm.$refs.lastName && _vm.$refs.lastName.hasError
                )?_c('v-icon',{attrs:{"size":"32px"}},[_vm._v("$vuetify.icons.defaultError")]):_c('v-icon')],1)],2)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',{staticClass:"pl-3 from-label-bold"},[_vm._v("Telefonnr.")]),_c('div',{staticClass:"split-field pa-0"},[_c('v-col',{staticClass:"pa-0 ma-0",staticStyle:{"max-width":"20%"}},[_c('v-text-field',{ref:"phone",staticClass:"code-input",attrs:{"outlined":"","dense":"","flat":"","required":"","disabled":true},model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}})],1),_c('v-col',{staticClass:"pa-0",staticStyle:{"max-width":"80%"}},[_c('v-text-field',{ref:"phone",staticClass:"phone-input",attrs:{"placeholder":"z.B. 172123456","width":"80%","clearable":"","outlined":"","dense":"","flat":"","required":"","rules":_vm.phoneNumberRules,"append-outer-icon":"$vuetify.icons.defaultError"},on:{"change":function($event){return _vm.formatPhoneNumber($event)}},model:{value:(_vm.reporter.phone),callback:function ($$v) {_vm.$set(_vm.reporter, "phone", $$v)},expression:"reporter.phone"}},[_c('template',{slot:"append-outer"},[(
                      _vm.attemptSubmit && _vm.$refs.phone && _vm.$refs.phone.hasError
                    )?_c('v-icon',{attrs:{"size":"32px"}},[_vm._v("$vuetify.icons.defaultError")]):_c('v-icon')],1)],2)],1)],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',{staticClass:"pl-3 from-label-bold"},[_vm._v("E-Mail")]),_c('v-text-field',{ref:"email",attrs:{"placeholder":"z.B. M.Mustermann@firma.de","clearable":"","outlined":"","dense":"","flat":"","rules":_vm.emailRules,"required":""},model:{value:(_vm.reporter.email),callback:function ($$v) {_vm.$set(_vm.reporter, "email", $$v)},expression:"reporter.email"}},[_c('template',{slot:"append-outer"},[(_vm.attemptSubmit && _vm.$refs.email && _vm.$refs.email.hasError)?_c('v-icon',{attrs:{"size":"32px"}},[_vm._v("$vuetify.icons.defaultError")]):_c('v-icon')],1)],2)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"pl-3 from-label-bold"},[_vm._v("Position ")]),_c('label',{staticClass:"from-label"},[_vm._v("(optional)")]),_c('v-text-field',{attrs:{"placeholder":"z.B. QM-Beauftragter","height":"25","clearable":"","outlined":"","dense":"","flat":""},model:{value:(_vm.reporter.position),callback:function ($$v) {_vm.$set(_vm.reporter, "position", $$v)},expression:"reporter.position"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"privacy-box",attrs:{"hide-details":"auto","ripple":false,"rules":_vm.validateCheckbox,"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.firstPrivacyText)+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.secondPrivacyText)+" ")])]},proxy:true}]),model:{value:(_vm.privacyAccepted),callback:function ($$v) {_vm.privacyAccepted=$$v},expression:"privacyAccepted"}})],1)],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"submit-btn",attrs:{"x-large":"","color":"primary"},on:{"click":_vm.setReporter}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-recycle ")]),_vm._v(" Quoten verbindlich versenden ")],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }