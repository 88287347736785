
import Vue from "vue";
import { emailRules, phoneRules } from "@/helpers";
import { parsePhoneNumber } from "libphonenumber-js";
import { ReporterInfo } from "@/interfaces";

export default Vue.extend({
  name: "ContactForm",

  data: () => {
    return {
      emailRules: emailRules,
      phoneRules: phoneRules,
      countryCode: "+49",
      reporter: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        position: "",
      } as ReporterInfo,
      privacyAccepted: false,
      formIsValid: false,
      attemptSubmit: false,
      nameRules: [
        (v: any) => !!v || "Ihr Name ist erforderlich.",
        (v: any) => v.length <= 50 || "Ihr Name ist zu lang.",
        (v: any) => v.length >= 3 || "Ihr Name ist zu kurz.",
      ],

      firstPrivacyText:
        "Ich bestätige die Sicherstellung der Dokumentations- " +
        "und Nachweisanforderungen gemäß § 15 Abs. 3. VerpackG. " +
        "Zur Plausibilisierung wird Zentek Services GmbH & Co. KG und / oder " +
        "einem von ihr beauftragten unabhängigen Dritten (z.B. externer Sachverständiger) " +
        "Einsicht in die erforderlichen Nachweise gewährt, bzw. die erforderlichen " +
        "Nachweise werden auf Anforderung zur Verfügung gestellt.",
      secondPrivacyText:
        "Diese Bestätigung bezieht sich ausschließlich auf die im Auftrag " +
        "der Zentek abgewickelten Transportverpackungen.",
    };
  },
  computed: {
    phoneNumberRules() {
      return [
        (v: any) =>
          (v && this.validatePhoneNumber(v)) ||
          "Bitte geben Sie eine gültige Telefonnummer ein.",
      ];
    },
    validateCheckbox() {
      return [this.privacyAccepted || ""];
    },
  },
  methods: {
    setReporter() {
      this.attemptSubmit = true;
      // @ts-ignore
      const isValid = this.$refs.contactForm
        ? this.$refs["contactForm"].validate()
        : false;
      if (isValid && this.formIsValid && this.privacyAccepted) {
        const phoneNumber = parsePhoneNumber(this.reporter.phone, "DE");
        if (phoneNumber) {
          this.reporter.phone = phoneNumber.number;
        }
        this.$emit("set-reporter", this.reporter);
      }
    },
    validatePhoneNumber(value: string) {
      try {
        const phoneNumber = parsePhoneNumber(value, "DE");
        return phoneNumber.isValid();
      } catch (e: any) {
        console.log(e.message);
      }
    },
    formatPhoneNumber(value: string) {
      try {
        const phoneNumber = parsePhoneNumber(value, "DE");
        if (phoneNumber) {
          this.reporter.phone = phoneNumber.nationalNumber;
        }
      } catch (e: any) {
        console.log(e.message);
      }
    },
  },
});
