
import Vue from "vue";
import AppDrawer from "@/components/AppDrawer.vue";
import FooterDrawer from "@/components/FooterDrawer.vue";
import AppUpdate from "@/components/AppUpdate.vue";
import SkeletonLoader from "@/components/atoms/SkeletonLoader.vue"
import LoadingComponent from "@/components/atoms/LoadingComponent.vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "App",
  components: {
    AppDrawer,
    FooterDrawer,
    AppUpdate,
    SkeletonLoader,
    LoadingComponent,
  },
  data: () => {
    return {
      //
    };
  },
  computed: {
    ...mapState({
      showSkeleton: (state: any) => state.disposerManagement.showSkeleton,
      showLoading: (state: any) => state.disposerManagement.dataIsLoading,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown; //breakpoint for mobile phone
    }
  },
});
