import axios from "axios";
import { ApiUrl } from "@/config/ApiUrl";

export const disposerModule = {
  state: () => ({
    disposer: {
      name: "",
      nameTwo: "",
      address: {
        street: "",
        zip: "",
        city: "",
        country: "Deutschland",
      },
      disposerNumber: 0,
      email: "",
    },
    report: null as any,
    notification: {
      reportId: "",
      businessYear: 0,
      done: false,
      submitDate: "",
    },
    expired: false,
    showMenu: false,
    successfullySubmitted: false,
    dataIsLoading: false,
    showSkeleton: false,
    submittedPayload: null as any,
    totalPercentRecycled: 0,
  }),
  mutations: {
    dataIsLoading(state: any, responseLoaded: any) {
      state.dataIsLoading = responseLoaded.loading;
      if(responseLoaded.loading === false && responseLoaded.valid === true && state.notification.done !== true && state.expired !== true) {
        state.showSkeleton = true;
        setTimeout(() => {
          state.showSkeleton = false;
        },2000
        )
      }
    },
    authSuccess(state: any, payload: any) {
      state.notification.reportId = payload.notification.reportId;
      state.notification.businessYear = payload.notification.businessYear;
      state.notification.done = payload.notification.done;
      state.disposer.name = payload.disposer.name;
      state.disposer.nameTwo = payload.disposer.nameTwo;
      state.disposer.disposerNumber = payload.disposer.disposerNumber;
      state.disposer.address.street = payload.disposer.address.street;
      state.disposer.address.zip = payload.disposer.address.zip;
      state.disposer.address.city = payload.disposer.address.city;
      state.disposer.email = payload.disposer.email;
      if (payload.isExpired) {
        state.expired = true;
      } else {
        state.report = payload.report;
        const totalGroup = payload.report.materialVolumes.length;
        let totalRecycled = 0;
        payload.report.materialVolumes.forEach((materialVolume: any) => {
          if (materialVolume.percentage && materialVolume.percentage.recycled) {
            totalRecycled += Number(materialVolume.percentage.recycled);
          }
        });
        state.totalPercentRecycled = Math.round(totalRecycled / totalGroup);
        state.showMenu = !payload.isExpired && !payload.notification.done;
      }
    },
    submitSuccess(state: any, payload: any) {
      state.submittedPayload = payload;
      const totalGroup = payload.materialVolumes.length;
      let totalRecycled = 0;
      payload.materialVolumes.forEach((materialVolume: any) => {
        if (materialVolume.percentage && materialVolume.percentage.recycled) {
          console.log('ha');
          console.log(materialVolume.percentage.recycled);
          totalRecycled += Number(materialVolume.percentage.recycled);
        }
      });
      state.totalPercentRecycled = Math.round(totalRecycled / totalGroup);
      state.successfullySubmitted = true;
      state.showMenu = false;
    },
  },
  actions: {
    loadDisposerData({ commit, dispatch }: any, reportId: string) {
      commit("dataIsLoading", { valid: false, loading: true });
      return new Promise((resolve, reject) => {
        axios({
          url: `${ApiUrl.DISPOSER_INFO}/${reportId}`,
          method: "GET",
        })
          .then((apiResponse: any) => {
            commit("authSuccess", apiResponse.data);
            const responseLoaded = {
              valid: true,
              loading: false,
            };
            commit("dataIsLoading", responseLoaded);
            resolve(apiResponse.data);
          })
          .catch((error) => {
            const responseLoaded = {
              valid: false,
              loading: false,
            };
            commit("dataIsLoading", responseLoaded);
            if (
              (error.message && error.message.includes("401")) ||
              (error.message && error.message.includes("404"))
            ) {
              resolve(error);
            } else {
              reject(error);
            }
          });
      });
    },
    submitQuote({ commit, dispatch }: any, payload: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${ApiUrl.SUBMIT_QUOTE}`,
          method: "POST",
          data: payload,
        })
          .then((apiResponse: any) => {
            commit("submitSuccess", payload);
            resolve(apiResponse);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    disposer(state: any) {
      return state.disposer;
    },
    report(state: any) {
      return state.report;
    },
    notification(state: any) {
      return state.notification;
    },
    valid(state: any) {
      return state.valid;
    },
    dataIsLoading(state: any) {
      return state.dataIsLoading;
    },
    showSkeleton(state: any) {
      return state.showSkeleton;
    }
  },
};
