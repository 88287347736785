import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

Vue.use(Router);
export default (to: any, from: any, next: any) => {
  if (to.query.done === "true") {
    next();
  } else {
    next({
      name: "AlreadySubmitted",
      params: { reportId: to.params.reportId },
    });
  }
};
