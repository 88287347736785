import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import FacebookLogo from "@/components/svg/FacebookLogo.vue";
import InstagramLogo from "@/components/svg/InstagramLogo.vue";
import Sending from "@/components/svg/Sending.vue";
import Raise from "@/components/svg/Raise.vue";
import Drop from "@/components/svg/Drop.vue";
import Equal from "@/components/svg/Equal.vue";
import One from "@/components/svg/One.vue";
import Two from "@/components/svg/Two.vue";
import Three from "@/components/svg/Three.vue";
import Four from "@/components/svg/Four.vue";
import LinkedIn from "@/components/svg/LinkedIn.vue";
import Folder from "@/components/svg/Folder.vue";
import Documents from "@/components/svg/Documents.vue";
import Language from "@/components/svg/Language.vue";
import Info from "@/components/svg/Info.vue";
import DefaultError from "@/components/svg/DefaultError.vue";
import '@mdi/font/css/materialdesignicons.css';
import XingLogo from "@/components/svg/XingLogo.vue";
import ZentekLogoWhite from '@/components/svg/ZentekLogoWhite.vue'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#50de9a",
        secondary: "#ffd566",
        third: "#78e3f0",
        fourth: "#303030",
        accent: "#78e3f0",
        error: "#F58686",
        info: "#DDDCE2",
        success: "#9CE9C2",
        warning: "#385F73",
        highlight: "#D4F4C7",
        textlight: "#666666",
        highlight2: "#48CEF8",
      },
    },
  },
  icons: {
    values: {
      zentekwhite: {
        component: ZentekLogoWhite,
      },
      xing: {
        component: XingLogo,
      },
      facebook: {
        component: FacebookLogo,
      },
      instagram: {
        component: InstagramLogo,
      },
      sendButton: {
        component: Sending,
      },
      drop: {
        component: Drop,
      },
      raise: {
        component: Raise,
      },
      equal: {
        component: Equal,
      },
      one: {
        component: One,
      },
      two: {
        component: Two,
      },
      three: {
        component: Three,
      },
      four: {
        component: Four,
      },
      linkedIn: {
        component: LinkedIn,
      },
      folder: {
        component: Folder,
      },
      documents: {
        component: Documents,
      },
      language: {
        component: Language,
      },
      info: {
        component: Info,
      },
      defaultError: {
        component: DefaultError,
      },
    },
    iconfont: 'mdi',
  },
  breakpoint: {
    thresholds: {
      xs: 429,
      sm: 769,
      md: 1200,
      lg: 1400,
    },
    mobileBreakpoint: 'sm' // This is equivalent to a value of 769
  },
});
