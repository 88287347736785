
import Vue from "vue";

export default Vue.extend({
  name: "RecycleConfirm",
  props: {
    sourceMaterialGroup: Object,
  },
  data: () => {
    return {
      materialGroup: {} as any,
    };
  },
  created() {
    this.materialGroup = this.sourceMaterialGroup;
  },
  computed: {
    errorState(): any {
      return {
        totalError: this.materialGroup.totalPercentage > 100 || this.materialGroup.totalPercentage < 100,
        recycled: this.materialGroup.percentage.recycled > 100,
        incinerated: this.materialGroup.percentage.incinerated > 100,
        fuelReplacement: this.materialGroup.percentage.fuelReplacement > 100,
        disposed: this.materialGroup.percentage.disposed > 100,
      };
    },
  },
  methods: {
    getErrorState(type: string) {
      if (this.errorState) {
        return this.errorState[type];
      }
      return false;
    },
  }
});
