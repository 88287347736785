import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuetify from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import VuetifyToast from "vuetify-toast-snackbar-ng";
import { VuetifyToastOptions } from "@/config/VuetifyToastOptions";
import "./registerServiceWorker";
import i18n from "./i18n";
import axios from "axios";

Vue.config.productionTip = false;
Vue.use(Vuetify, { iconfont: "md" });
Vue.use(VuetifyToast, VuetifyToastOptions);
Vue.prototype.$http = axios;

const x = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
