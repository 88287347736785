import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

Vue.use(Router);

export default (to: any, from: any, next: any) => {
  const reportId = to.params.reportId;

  if (!reportId) {
    next({ name: "WrongPage" });
  }
  store
    .dispatch("loadDisposerData", reportId)
    .then((res: any) => {
      if (to.name === "Dashboard" && res.isExpired) {
        // if the report is still open but the link is expired
        next({ name: "ExpiredLink", params: { reportId } });
      } else if (to.name === "Dashboard" && res.notification.done) {
        // if the user has already submitted the report
        next({ name: "AlreadySubmitted", params: { reportId } });
      } else if (
        to.name !== "Dashboard" &&
        res.disposer &&
        res.disposer.disposerNumber &&
        res.notification.done === false &&
        res.isExpired === false
      ) {
        // if the user has not submitted the report yet
        next({ name: "Dashboard", params: { reportId } });
      } else {
        next();
      }
    })
    .catch((err: any) => {
      next({ name: "WrongPage" });
    });
};
