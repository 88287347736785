
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "SuccessPage",
  mounted() {
    this.$router.replace({params: {done: ''}});
  },
  computed: {
    ...mapState({
      submittedPayload: (state: any) => state.disposerManagement.submittedPayload,
      totalPercentRecycled: (state: any) => state.disposerManagement.totalPercentRecycled,
    }),
  },
});
