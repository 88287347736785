import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import PrivacyPolicyPage from "../views/PrivacyPolicyPage.vue";
import ImprintPage from "../views/ImprintPage.vue";
import ExpiredLink from "../views/ExpiredLink.vue";
import SuccessPage from "../views/SuccessPage.vue";
import alreadySubmitted from "../views/AlreadySubmitted.vue";
import WrongPage from "../views/WrongPage.vue";
import isLinkValid from "@/guards/isLinkValid";
import isRefreshed from "@/guards/isRefreshed";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/yearly-report/:reportId",
    name: "Dashboard",
    beforeEnter: isLinkValid,
    component: Dashboard,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicyPage
  },
  {
    path: "/impressum",
    name: "ImprintPage",
    component: ImprintPage,
  },
  {
    path: "/expired/:reportId",
    name: "ExpiredLink",
    beforeEnter: isLinkValid,
    component: ExpiredLink,
  },
  {
    path: "/report-success/:reportId&done",
    name: "Success",
    component: SuccessPage,
    beforeEnter: isRefreshed
  },
  {
    path: "/submitted/:reportId",
    name: "AlreadySubmitted",
    beforeEnter: isLinkValid,
    component: alreadySubmitted,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "WrongPage",
    component: WrongPage,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
