
import Vue from "vue";
import { useRoute } from "vue-router/composables";
import { mapState } from "vuex";

export default Vue.extend({
  name: "AlreadySubmittedPage",
  computed: {
    route() {
      return useRoute();
    },
    ...mapState({
      report: (state: any) => state.disposerManagement.report,
      totalPercentRecycled: (state: any) => state.disposerManagement.totalPercentRecycled,
    }),
  },
});
