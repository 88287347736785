
import Vue from "vue";
export default Vue.extend({
  props: {
    isTvp: Boolean,
  },
  data: () => {
    return {};
  },
});
