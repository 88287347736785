
import Vue from "vue";
import Imprint from "@/components/atoms/Imprint.vue";
import LegalNotice from "@/components/atoms/LegalNotice.vue";
import PrivacyPolicyPage from "@/components/PrivacyPolicyPage.vue";

export default Vue.extend({
  components: {
    Imprint,
    LegalNotice,
    PrivacyPolicyPage,
  },
  data: () => {
    return {
      imprint: false,
      legalNotice: false,
      dataPrivacy: false,
      dialogStatus: false,
      mobileDialogStatus: false,
    };
  },
  computed: {},
  watch: {
    dialogStatus(value) {
      if (value !== true) {
        this.imprint = false;
        this.legalNotice = false;
        this.dataPrivacy = false;
      }
    },
  },
  methods: {
    openBottomSheet(dialogModel: any) {
      this.mobileDialogStatus = true;
      this.$data[dialogModel] = true;
    },
    closeBottomSheet() {
      this.mobileDialogStatus = false;
      this.legalNotice = false;
      this.imprint = false;
      this.dataPrivacy = false;
    },
    openDialog(dialogModel: any) {
      this.dialogStatus = true;
      this.$data[dialogModel] = true;
    },
  },
});
