
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  props: {
    successMessage: Boolean,
    submittedReport: Boolean,
  },
  data: () => {
    return {
      drawer: false,
      reportSuccessPage: false,
      reportAlreadySubmittedPage: false,
    };
  },
  computed: {
    ...mapState({
      showMenu: (state: any) => state.disposerManagement.showMenu,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
});
