<template>
   <v-sheet class="px-0 px-md-12 privacy-policy">
    <v-card-title class="display-1 font-weight-bold mt-8 pb-8 text-left text-uppercase">
      Datenschutz
    </v-card-title>
    <v-divider class="pb-3 d-sm-none"/>
    <v-card-text class="text-left black--text mb-10">
      <p>Stand: 2. Mai 2019</p>
      <p class="mt-8 font-weight-bold">1. Allgemeines</p>
      <p>
        Die unter
        <a
          href="http://recycling-monitor.zentek.de/"
          target="_blank"
          class="no-deco"
        >
          <span> http://recycling-monitor.zentek.de/</span>
        </a>
         und allen hierauf ggf. verweisenden weiteren Internetadressen
        zugängliche Internetseite („Website“) wird von der Zentek Services GmbH
        & Co. KG („Zentek“) betrieben; die Kontaktdaten entnehmen Sie bitte dem
        Impressum. Zentek nimmt den Datenschutz und die diesem Zweck dienenden
        gesetzlichen Verpflichtungen sehr ernst. In dieser Datenschutzerklärung
        erfahren Sie, welche Daten auf der Website bei der Kontaktaufnahme zu
        Zentek und in unseren Geschäftsabläufen erhoben, verarbeitet, in welcher
        Weise genutzt werden und wie Zentek den Schutz Ihrer Daten
        gewährleistet.
      </p>
      <p>
        Wir verarbeiten personenbezogene Daten, die wir für die Erfüllung von
        Verträgen oder zur Durchführung vorvertraglicher Maßnahmen von unseren
        Kunden oder anderen Betroffenen erhalten. Zudem verarbeiten wir – soweit
        für die Erbringung unserer Dienstleistung erforderlich –
        personenbezogene Daten, die wir aus öffentlich zugänglichen Quellen
        (z.B. Schuldnerverzeichnisse, Handels- und Vereinsregister, Presse,
        Internet) zulässigerweise gewinnen oder die uns von sonstigen Dritten
        (z.B. Wirtschaftsauskunfteien) berechtigt übermittelt werden.
      </p>
      <p>
        Für weiterführende Informationen oder Fragen zu der Verwendung Ihrer
        Daten, die durch diese Datenschutzerklärung nicht beantwortet werden,
        wenden Sie sich bitte an den Datenschutzbeauftragten:
        <br />
        Hans-Ulrich Förster, Zentek Services GmbH & Co. KG, Ettore-Bugatti-Str.
        6-14, 51149 Köln
      </p>
      <p class="mt-8 font-weight-bold">2. Personenbezogene Daten</p>
      <p>
        Personenbezogene Daten erlauben die Feststellung der Identität des
        Nutzers dieser Website bzw. des Kunden. Hierunter fallen u.a. Name,
        Vorname, Anschrift, Telefonnummer und E-Mail-Adresse. Die Nutzung
        unserer Website ist grundsätzlich möglich, ohne dass Sie Ihre Identität
        ganz oder teilweise offenlegen müssen.
      </p>
      <p>
        Erst bei einer vertraglichen oder vorvertraglichen Maßnahme oder der
        Kontaktaufnahme zu Zentek, z.B. über die Website, ist die Offenlegung
        Ihrer Identität und die Preisgabe personenbezogener Daten erforderlich.
        Ohne die Offenlegung Ihrer Identität und die Preisgabe personenbezogener
        Daten ist eine Bearbeitung Ihrer Anfrage durch Zentek nicht möglich. Es
        ist deshalb Ihre freie Entscheidung, ob Sie zu Zwecken der
        Kontaktaufnahme mit uns personenbezogene Daten an uns übermitteln
        wollen.
      </p>
      <p class="mt-8 font-weight-bold">
        3. Automatische Datenverarbeitung auf der Website
      </p>
      <p>
        Die Website wird von einem hierauf spezialisierten Computer („Server“)
        ausgeliefert, der automatisch bei jedem Aufruf der Website Informationen
        erhebt und in Protokolldateien („Log-Files“) speichert. Hierbei handelt
        es sich um (1) den verwendeten Browser einschließlich der
        Versionsnummer, (2) das verwendete Betriebssystem, (3) die Adresse der
        von Ihnen zuvor besuchten Internetseite („Referrer URL“), (4) die
        Adresse des Computers, mit der Sie auf die Website zugegriffen haben
        („IP-Adresse“) sowie (5) Uhrzeit und Datum des Abrufs dieser Website.
      </p>
      <p>
        Die vorgenannten Informationen werden von Ihrem Browser übermittelt,
        sofern Sie dies nicht durch eine abweichende Konfiguration Ihres
        Browsers oder eine von Ihnen eingesetzte Sicherheitssoftware
        unterbinden. Zentek kann die in den Log-Files vom Server gespeicherten
        Daten einer bestimmten Person nicht zuordnen. Es erfolgt auch keine
        Zusammenführung der Daten in den Log-Files des Servers mit anderen
        Datenquellen, die bei uns ggf. zur Verfügung stehen.
      </p>
      <p class="mt-8 font-weight-bold">4. Verwendung Ihrer Daten</p>
      <p>
        Wir verarbeiten personenbezogene Daten im Einklang mit den Bestimmungen
        der EU-Datenschutz-Grundverordnung (DS-GVO) und dem
        Bundesdatenschutzgesetz (BDSG) in folgenden Fällen:
      </p>
      <ul class="my-4">
        <li>nach Ihrer Einwilligung</li>
        <li>
          aufgrund eines Vertrages oder zur Durchführung vorvertraglicher
          Maßnahmen (ggf. auf Anfrage)
        </li>
        <li>
          die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
          erforderlich
        </li>
        <li>
          die Verarbeitung ist erforderlich, um lebenswichtige Interessen der
          betroffenen Person oder einer anderen natürlichen Person zu schützen
        </li>
        <li>
          die Verarbeitung ist für die Wahrnehmung einer Aufgabe erforderlich,
          die im öffentlichen Interesse liegt
        </li>
        <li>
          die Verarbeitung ist zur Wahrung unserer berechtigten Interessen oder
          eines Dritten erforderlich, sofern nicht die Interessen oder
          Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz
          personenbezogener Daten erfordern, überwiegen.
        </li>
      </ul>
      <p>
        Nehmen Sie über die Website Kontakt zu uns auf, werden die von Ihnen
        abgefragten personenbezogenen Daten ausschließlich zum Zweck der
        Bearbeitung Ihrer Kontaktaufnahme erhoben, gespeichert und genutzt, also
        insbesondere zur Bearbeitung Ihrer allgemeinen Anfrage an uns über das
        Kontaktformular, Ihrer Anfrage nach einer Registrierung für einen
        geschützten Zugang oder zur Bearbeitung und Abwicklung einer von Ihnen
        über die Website übermittelten Vertragsanfrage oder eines zuvor
        abgeschlossenen Vertrags.
      </p>
      <p>
        Auch Ihre E-Mail-Adresse verwenden wir ausschließlich zu dem Ihrer
        Kontaktaufnahme über die Website dienenden Zweck. Nur falls dies von
        Ihnen ausdrücklich gewünscht wird, übermitteln wir an Sie im Rahmen der
        von Ihnen erteilten Einwilligung auch über den Zweck der Kontaktaufnahme
        hinausgehende E-Mails mit allgemeinen Informationen und Werbung an Sie.
        Ihre Einwilligung in diese weitergehende Verwendung Ihrer E-Mail-Adresse
        können Sie selbstverständlich jederzeit per E-Mail oder schriftlich an
        die im Impressum genannte Anschrift widerrufen, worauf wir Sie auch in
        jeder E-Mail mit allgemeinen Informationen oder werblichem Inhalt
        gesondert hinweisen werden.
      </p>
      <p class="mt-8 font-weight-bold">5. Weitergabe der Daten</p>
      <p>
        Auch Ihre E-Mail-Adresse verwenden wir ausschließlich zu dem Ihrer
        Kontaktaufnahme über die Website dienenden Zweck. Nur falls dies von
        Ihnen ausdrücklich gewünscht wird, übermitteln wir an Sie im Rahmen der
        von Ihnen erteilten Einwilligung auch über den Zweck der Kontaktaufnahme
        hinausgehende E-Mails mit allgemeinen Informationen und Werbung an Sie.
        Ihre Einwilligung in diese weitergehende Verwendung Ihrer E-Mail-Adresse
        können Sie selbstverständlich jederzeit per E-Mail oder schriftlich an
        die im Impressum genannte Anschrift widerrufen, worauf wir Sie auch in
        jeder E-Mail mit allgemeinen Informationen oder werblichem Inhalt
        gesondert hinweisen werden.
      </p>
      <p>
        Im Hinblick auf die Datenweitergabe an Empfänger außerhalb der Zentek
        ist zu beachten, dass Daten grundsätzlich nur weitergegeben werden, wenn
        gesetzliche Bestimmungen dies vorschreiben, ein
        Auftragsverarbeitungsverhältnis oder eine Einwilligung vorliegt.
      </p>
      <p>
        Eine Übermittlung Ihrer Daten in ein Drittland oder an eine
        internationale Organisation erfolgt nach den gesetzlichen Bestimmungen.
        Der Umfang der Übermittlung ist beschränkt auf das zur Zweckerreichung
        erforderliche Minimum.
      </p>
      <p class="mt-8 font-weight-bold">6. Speicherdauer</p>
      <p>
        Wir verarbeiten und speichern Ihre personenbezogenen Daten, solange dies
        für die Erfüllung unserer vertraglichen und gesetzlichen Pflichten
        (handels- und steuerrechtliche Aufbewahrungspflichten – in der Regel
        zwei bis zehn Jahre -, gesetzliche Verjährungsvorschriften – 3 bis 30
        Jahre -) erforderlich ist. Danach werden Daten regelmäßig gelöscht.
      </p>
      <p class="mt-8 font-weight-bold">7. Erstellung von Nutzungsprofilen</p>
      <p>
        Um die Website zu verbessern sowie zu Zwecken der Werbung und zur
        Marktforschung analysieren wir das Verhalten der Nutzer dieser Website.
        Zu diesem Zweck erstellt Zentek anonymisierte Nutzungsprofile, sofern
        Sie dem nicht widersprechen. Zentek stellt sicher, dass die
        anonymisierten Nutzungsprofile nicht mit Ihren personenbezogenen Daten
        zusammengeführt werden. Der Widerruf gegen die Erstellung anonymisierter
        Nutzungsprofile kann per E-Mail oder schriftlich an die im Impressum
        genannte Person gerichtet werden.
      </p>
      <p class="mt-8 font-weight-bold">8. Datenschutzrechte</p>
      <p>
        Wir erteilen Ihnen jederzeit unentgeltlich und unverzüglich gemäß den
        gesetzlichen Bestimmungen Auskunft über die zu Ihnen durch uns
        gespeicherten personenbezogenen Daten. Wenden Sie sich zu diesem Zweck
        bitte an die im Impressum genannten Personen oder an den
        Datenschutzbeauftragten.
      </p>
      <p>
        Jede betroffene Person hat außerdem das Recht auf Berichtigung, das
        Recht auf Löschung, das Recht auf Einschränkung der Verarbeitung, das
        Recht auf einzelfallbezogenen Widerspruch, das Recht auf Widerspruch
        gegen eine Verarbeitung von Daten für Zwecke der Direktwerbung sowie das
        Recht auf Datenübertragbarkeit. Betroffene Personen können jederzeit
        Beschwerden bei einer zuständigen Datenschutzaufsichtsbehörde
        vorbringen.
      </p>
      <p>
        Eine erteilte Einwilligung in die Verarbeitung personenbezogener Daten
        kann jederzeit uns gegenüber widerrufen werden. Dies gilt auch für den
        Widerruf von Einwilligungserklärungen, die vor der Geltung der DSGVO,
        also vor dem 2. Mai 2019, uns gegenüber erteilt worden sind.
      </p>
      <p class="mt-8 font-weight-bold">9. Hinweis zu sozialen Netzwerken</p>
      <p>
        Wenn Sie Zentek in den sozialen Netzwerken Facebook, Instagram, Xing,
        YouTube und LinkedIn (Betreiber) aufrufen, sind deren
        Datenschutzerklärungen für die Verarbeitung entscheidend. Diese
        Erklärungen sind über die Webseiten der Betreiber jederzeit abrufbar.
        Sie sollten sich deshalb mit der im Einzelfall geltenden
        Datenschutzerklärung über Ihre Rechte – auch in Bezug auf Cookies – bei
        dem jeweiligen Betreiber informieren. Sollten Sie in einem sozialen
        Netzwerk Inhalte an uns weitergeben oder sich in sonstiger Weise
        gegenüber Zentek zu erkennen geben, verarbeiten wir personenbezogene
        Daten von Ihnen.
      </p>
      <p>
        Einige der sozialen Netzwerke bieten darüber hinaus eine
        Nachrichtenfunktion an über die Sie direkt mit uns in Kontakt treten
        können. In diesen Fällen verarbeiten wir Ihre personenbezogenen Daten
        entsprechend dieser Erklärung. Hinsichtlich Facebook ist das Folgende zu
        beachten. Der Zusatz für Verantwortliche (<a
          href="https://www.facebook.com/legal/controller_addendum"
          target="_blank"
          class="no-deco"
          >https://www.facebook.com/legal/controller_addendum</a
        >), gilt insofern, als er durch diese Bezugnahme ausdrücklich
        Bestandteil von Nutzungsbedingungen für Facebook-Produkte wird, wie z.
        B. die Nutzungsbedingungen für Facebook Business-Tools (jegliche solche
        Nutzungsbedingungen sind „Nutzungsbedingungen für Umfasste Produkte“ und
        die umfassten Facebook-Produkte „Umfasste Produkte“).
      </p>
      <p>
        Zentek und Facebook Ireland arbeiten mit diesem Zusatz, um die
        jeweiligen Verantwortlichkeiten für die Erfüllung der Verpflichtungen
        gemäß DS-GVO hinsichtlich der gemeinsamen Verarbeitung festzulegen (wie
        in den Nutzungsbedingungen für Umfasste Produkte). Zentek stellt
        Betroffenen insoweit diese Informationen wegen der Verwendung umfasster
        Produkte bereit: Facebook Ireland ist ein gemeinsam Verantwortlicher der
        gemeinsamen Verarbeitung; die gemäß Artikel 13 Abs. 1 lit. a) und b)
        DS-GVO erforderlichen Informationen befinden sich in der Datenrichtlinie
        von Facebook Ireland unter
        <a
          href="https://www.facebook.com/about/privacy"
          target="_blank"
          class="no-deco"
          >https://www.facebook.com/about/privacy</a
        >. Dort finden sich auch Informationen dazu, wie Facebook Ireland
        personenbezogene Daten verarbeitet, einschließlich der Rechtsgrundlage,
        auf die Facebook Ireland sich stützt, und der Möglichkeiten zur
        Wahrnehmung der Rechte betroffener Personen gemäß Artikel 15-20 der
        DS-GVO gegenüber Facebook Ireland.
      </p>
    </v-card-text>
  </v-sheet>
</template>
<script>
import Vue from "vue";
export default Vue.extend({
  name: "PrivacyPolicyPage",
  props: { productType: String },
});
</script>
<style lang="scss" scoped>
@include lt-md{
  .mobile-card-style{
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
.privacy-policy {
  width: 81%;
  max-width: 1600px !important;
  margin: auto;
}
</style>
